import { Container } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useEvent,useStore } from 'effector-react';
import { useLang } from 'locales';
import { $filter, $filterOptions, $isLoading, $schedule, changeFilter } from 'models/schedule';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import { CityField } from './Fields/CityField';
import { LanguageField } from './Fields/LanguageField';
import { LocationField } from './Fields/LocationField';
import { FilterButton } from './FilterButton';
import {MariyaButton} from './MariyaButton';
import { DesktopFilter } from './SwitchFilter/DesktopFilter';
import { MobileFilter } from './SwitchFilter/MobileFilter';

const FiltersContainer = styled(Container)<{ theme: DefaultTheme }>(({theme}) => ({
  border: '1px solid #dadada',
  padding: '15px',
  borderRadius: '4px',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    margin: '8px 8px 16px',
    boxShadow: '0 4px 10px rgba(93,62,129,.15)',
    border: '0',
    padding: '8px',
    width: 'unset',
    display: 'block'
  },
  boxSizing: 'border-box',
  '& .active-block': {
    display: 'block',
  },
  '& .active-flex': {
    display: 'flex',
  }
}));

const FiltersTitle = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
  '&.active-flex': {
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0'
    },
  }
}));

const Filters = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'none',
  marginTop: 15,
}));

const FiltersWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  background: 'rgba(93,62,129,.03)',
  borderRadius: '10px',
  padding: '20px 40px 30px',
  marginBottom: '30px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 10px 0',
    margin: 0,
    borderRadius: '10px 10px 0 0',
  },
}));

const FiltersSection = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
}));

export const Filter = () => {
  const theme = useTheme<DefaultTheme>();

  const schedule = useStore($schedule);
  const filter = useStore($filterOptions);

  const setFilter = useEvent(changeFilter);

  const isLoading = useStore($isLoading);
  const filterState = useStore($filter);

  const ctx = useLang();

  return(
    <FiltersContainer maxWidth='lg' theme={theme}>
      <FiltersTitle className={filterState.active ? 'active-flex' : ''} theme={theme}>
        <FilterButton
          onClick={(_) => {setFilter({active: !filterState.active});}}
          active={filterState.active}
        />
        {/* Display Radio Button on desktop only */}
        <MariyaButton />
      </FiltersTitle>
      {/* Filters */}
      <Filters className={filterState.active ? 'active-block' : ''} theme={theme}>
        <FiltersWrapper theme={theme}>
          <FiltersSection theme={theme}>
            <CityField />
            <LanguageField />
            <LocationField />
          </FiltersSection>
        </FiltersWrapper>
        <FiltersSection theme={theme}>
          <MobileFilter
            containerSx={{
              display: {
                xs: 'block',
                sm: 'block',
                md: 'none',
              },
            }}
          />
          <DesktopFilter
            containerSx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
              },
            }}
          />
        </FiltersSection>
      </Filters>
    </FiltersContainer>
  );
};
