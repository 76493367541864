import { Box, FormControlLabel,FormGroup,SvgIcon,Switch } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useEvent, useStore } from 'effector-react';
import { useLang } from 'locales';
import { $filter, $filterOptions, changeFilter } from 'models/schedule';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import RorateCandle from './raraty.svg';
import { SwitchFilterProps } from './types';

const FilterContainer = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  '& .title': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    position: 'relative',
    paddingLeft: '39px',
    flex: '1',
    '& .subtitle': {
      font: `normal 15px/18.3px ${theme.fonts.calibri.main}`,
    }
  },
  marginRight: '100px'
}));

const FilterWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  font: `normal 24px/29.3px ${theme.fonts.calibri.main}`,
  color: theme.colors.tundora,
}));

const SvgIconStyled = styled(SvgIcon)<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'block',
  width: '23px',
  height: '18px',
  position: 'absolute',
  top: '6px',
  left: '0',
  fill: theme.palette.primary.main,
}));

const RorateCandleStyled = styled(RorateCandle)<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'block',
  width: '50px',
  height: '35px',
  position: 'absolute',
  top: '0px',
  left: '0',
  fill: 'white',
  '& path': {
    stroke: theme.palette.primary.main,
  },
}));

const SwitchStyled = styled(Switch)<{ theme: DefaultTheme }>(({theme}) => ({
  marginLeft: '15px',
  position: 'absolute',
  right: '-66px',
  top: -5
}));

const StyledFormLabel = styled(FormControlLabel)<{ theme: DefaultTheme }>(({theme}) => ({
  '& .Mui-disabled': {
    opacity: '.5'
  },
  marginLeft: 0,
  position: 'relative',
}));

export const DesktopFilter = ({
  containerSx
}: SwitchFilterProps) => {
  const theme = useTheme<DefaultTheme>();
  const setFilter = useEvent(changeFilter);
  const filterState = useStore($filter);
  const filter = useStore($filterOptions);


  const ctx = useLang();

  return (
    <Box sx={containerSx}>
      <FilterContainer theme={theme}>
        <FormGroup>
          <StyledFormLabel
            control={
              <SwitchStyled
                onChange={(e) => setFilter({
                  online: !filterState.online,
                })}
                disabled={filter.online.filter((e) => e.value === 'true').length === 0}
                theme={theme}
                checked={filterState.online}
              />
            }
            theme={theme}
            labelPlacement={'start'}
            label={
              <FilterWrapper theme={theme}>
                <div className='title'>
                  <SvgIconStyled viewBox='0 0 26 20' theme={theme}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M24.6954 17.1496C25.4926 15.7833 25.8332 13.5804 25.8332 9.99675C25.8332 6.42743 25.4958 4.23725 24.6901 2.83439C23.8827 1.37761 22.925 0.915224 21.0245 0.804238C19.7271 0.716296 16.5025 0.666687 13.0029 0.666687C9.49588 0.666687 6.26986 0.716278 4.96268 0.804927C3.07281 0.915488 2.11497 1.3789 1.30042 2.83972C0.506452 4.23774 0.166504 6.43862 0.166504 10.0098C0.166504 13.5607 0.508098 15.7727 1.3074 17.1661C2.11135 18.6081 3.05392 19.066 4.95988 19.1998C6.32972 19.28 9.73832 19.3334 13.0029 19.3334C16.2602 19.3334 19.6672 19.28 21.0245 19.2007C22.948 19.066 23.8904 18.6074 24.6954 17.1496ZM20.8777 3.13292C22.0664 3.20239 22.2869 3.30925 22.6637 3.99126C23.2123 4.94135 23.4998 6.80069 23.4998 10.0098C23.4998 13.1947 23.2108 15.0642 22.6663 15.9978C22.2881 16.682 22.0692 16.7885 20.8749 16.8722C19.5903 16.9472 16.2163 17 13.0029 17C9.78232 17 6.4067 16.9472 5.10971 16.8713C3.93018 16.7884 3.7081 16.6805 3.33836 16.0175C2.79018 15.0616 2.49984 13.1816 2.49984 9.99675C2.49984 6.81384 2.78863 4.94414 3.33388 3.98401C3.70937 3.31067 3.93302 3.20247 5.10978 3.13359C6.35576 3.04914 9.55145 3.00002 13.0029 3.00002C16.447 3.00002 19.6412 3.04916 20.8777 3.13292ZM10.6665 6.50002L16.4998 10L10.6665 13.5V6.50002Z"/>
                  </SvgIconStyled>
                  <span>{ctx.filterAttendance.title}</span>
                  <span className='subtitle'>{ctx.filterAttendance.subtitle}</span>
                </div>
              </FilterWrapper>
            }/>
        </FormGroup>
      </FilterContainer>
      {/* <FilterContainer theme={theme}>
        <FormGroup>
          <StyledFormLabel
            control={
              <SwitchStyled
                onChange={(e) => setFilter({
                  rorate: !filterState.rorate,
                })}
                disabled={filter.rorate.filter((e) => e.value === 'true').length === 0}
                theme={theme}
                checked={filterState.rorate}
              />
            }
            theme={theme}
            labelPlacement={'start'}
            label={
              <FilterWrapper theme={theme}>
                <div className='title'>
                  <RorateCandleStyled viewBox='20 0 100 100' theme={theme} />
                  <span>{ctx.filterRorate.title}</span>
                </div>
              </FilterWrapper>
            }/>
        </FormGroup>
      </FilterContainer> */}
    </Box>
  );
};
