import { Container } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { SocialNetworks } from 'components/MainHeader/SocialNetworks/mobile';
import { useWindowSize } from 'components/utils';
import { getActualDays } from 'components/utils';
import { useEvent, useStore } from 'effector-react';
import { defaultLang } from 'locales';
import { $filter, $isLoading,$schedule, fetchSchedule } from 'models/schedule';
import { Schedule } from 'models/schedule/types';
import { $language } from 'models/staticData';
import React, { useEffect,useState } from 'react';
import { useCookies } from 'react-cookie';
import { DefaultTheme } from 'styles/Theme/types';

import NavBtn from './NavBtn';
import ScheduleTab from './ScheduleTab';


const ScheduleBox = styled('div')(() => ({
  position: 'relative',
  border: '1px solid #dadada',
  visibility: 'visible',
  animationDuraion: '1.5s',
  animationDelay: '.2s',
  animationName: 'fadeInUp',
  '&.clearfix': {
    '&::before, &::after': {
      display: 'table',
      content: '" "',
      boxSizing: 'border-box'
    },
    '&::after': {
      clear: 'both'
    }
  }
}));

const TabBoxNav = styled('ul')<{ theme: DefaultTheme }>(({theme}) => ({
  width: '17.5%',
  float: 'left',
  display: 'block',
  boxSizing: 'border-box',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    float: 'none',
    display: 'flex',
    width: '100%',
    marginBottom: '10px',
    position: 'sticky',
    top: '0',
    zIndex: '2',
    backgroundColor: '#fff'
  },
}));

const TabBoxWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  float: 'left',
  width: '82%',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}));

export type ScheduleProps = {
  data: Schedule[],
}

export const ScheduleComponent = () => {
  const theme = useTheme<DefaultTheme>();
  const date = new Date();
  date.setHours(0,0,0,0);

  const [active, setActive] = useState(date);
  const [width, _] = useWindowSize();
  const isMobile = width <= 900;
  const [cookies, setCookies] = useCookies(['cityId']);

  const scheduleResponse = useStore($schedule);
  const lang = useStore($language);
  const filter = useStore($filter);
  const isLoading = useStore($isLoading);

  const handleFetch = useEvent(fetchSchedule);

  useEffect(() => {
    handleFetch(lang || defaultLang);
  }, [lang, active, handleFetch, filter, cookies.cityId]);


  const onClick = (index: Date) => {
    setActive(index);
  };

  const dates = getActualDays(lang || defaultLang, isMobile);
  const activeSchedule = scheduleResponse.find(elem => elem.date.getTime() === active.getTime());
  const notEmptyDates = scheduleResponse.map(elem => elem.date);

  return (
    <Container maxWidth='lg' disableGutters>
      <ScheduleBox className={'clearfix'}>
        <TabBoxNav theme={theme} className={'clearfix'}>
          {dates.map((date, key) =>
            <NavBtn
              date={date.date}
              onClick={onClick}
              activeDate={active}
              day={date.dayName}
              dateHumanFormat={date.humanFormat}
              key={key}
              disabled={isLoading || (notEmptyDates.find(elem => elem.getTime() === date.date.getTime()) ? false : true)}
            />
          )}
        </TabBoxNav>
        <TabBoxWrapper theme={theme}>
          <ScheduleTab
            massHours={activeSchedule ? activeSchedule.massHours : []}
          />
        </TabBoxWrapper>
      </ScheduleBox>
      <SocialNetworks />
    </Container>
  );
};
