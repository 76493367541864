import { Autocomplete, AutocompleteRenderOptionState, PopperProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FilterField, OptionStyled } from 'components/Filter/Fields/style';
import { FilterInput } from 'components/Filter/FilterInput';
import { useWindowSize } from 'components/utils';
import { useEvent, useStore } from 'effector-react';
import { useLang } from 'locales';
import { $filter, $filterOptions, changeFilter } from 'models/schedule';
import { FilterParish } from 'models/schedule/types';
import React, { useEffect, useState } from 'react';
import { DefaultTheme } from 'styles/Theme/types';


type AutocompleteValue = {
  label: string;
  id: string;
} | null;


export const LocationField = () => {
  const theme = useTheme<DefaultTheme>();

  const ctx = useLang();
  const options = useStore($filterOptions);
  const filter = useStore($filter);
  const setFilter = useEvent(changeFilter);
  const [width, _] = useWindowSize();
  const isMobile = width <= 900;
  const [selectOptions, setselectOptions] = useState<FilterParish[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setselectOptions(options.parish.map(
      instance => (
        {
          label: instance.name,
          id: instance.value
        }
      )
    ));
  }, [options]);

  const onChange = (_: React.SyntheticEvent, parishId:AutocompleteValue, reason: String): void => {
    if (reason === 'clear') setOpen(true);
    setFilter({
      parishId,
    });
  };

  const commonPopperProps: Partial<PopperProps> = {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: false,
          rootBoundary: 'document',
          padding: 8,
        },
      },
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altAxis: false,
          altBoundary: false,
          tether: false,
          rootBoundary: 'viewport',
          padding: 8,
        },
      }
    ]
  };
  const mobilePopperProps: Partial<PopperProps> = {
    placement: 'auto-end',
    style: {
      width: '100%',
    }
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLElement>,
    option: FilterParish,
    state: AutocompleteRenderOptionState
  ) => {
    return (
      <OptionStyled {...props} theme={theme}>
        <span>{option.label}</span>
      </OptionStyled>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const value = selectOptions.length === 1 ? selectOptions[0] : selectOptions.find(elem => elem.id === filter.parishId?.id) || null;

  return (
    <FilterField theme={theme} sx={{
      width: '64%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        order: '1'
      }
    }}>
      <div className='fieldLabel'>
        {ctx.filterParishLabel}
      </div>
      <Autocomplete
        disablePortal
        componentsProps={{
          popper: isMobile ? {...mobilePopperProps, ...commonPopperProps} : {...commonPopperProps}
        }}
        renderOption={renderOption}
        options={selectOptions}
        fullWidth
        renderInput={(params) => <FilterInput {...params}/>}
        onChange={onChange}
        value={value}
        noOptionsText={ctx.noOptionsText}
        blurOnSelect
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </FilterField>
  );
};
