import {styled, useTheme} from '@mui/material/styles';
import SimpleModal from 'components/Modal';
import { useLang } from 'locales';
import { MassHoursData } from 'models/schedule/types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DefaultTheme } from 'styles/Theme/types';
import {elemClick} from 'utils/gtm';

import Raraty from './raraty.svg';

const PanelWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  display: 'flex',
  padding: '25px 0 0',
  marginBottom: '50px',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '4px',
  boxShadow: '0 1px 1px rgba(0,0,0,.05)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: 0,
  },
  '& .info': {
    flex: '1',
    position: 'relative',
    minHeight: '50px',
    paddingRight: '10px',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 20px'
    },
  },
  '& .mass-parish-name, & .mass-parish-address, & .mass-language': {
    marginBottom: '16px',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  '& .mass-image-wrapper': {
    flex: '0 0 162px',
    padding: '0 25px',
    minHeight: '100px',
    [theme.breakpoints.down('md')]: {
      minHeight: '100px',
      flex: '1',
      padding: '10px 20px 10px',
    },
    '&.need-update': {
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#FFEA00',
      }
    },
    '& .img-circle': {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '50%',
      width: '110px',
      height: '110px',
      [theme.breakpoints.down('md')]: {
        width: '80px',
        height: '80px',
        minWidth: '80px',
      },
      '& .overlay': {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        background: 'rgba(0,0,0,0.5)',
      },
      '& .rorate': {
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
          left: '-10px',
          top: '-7px',
        },
        top: 0,
        left: 0,
      }
    },
    '& .image': {
      border: '1px solid #dadada',
      overflow: 'hidden',
      position: 'relative',
      width: '110px',
      height: '110px',
      [theme.breakpoints.down('md')]: {
        // left: '20px',
        width: '80px',
        height: '80px',
      },
    }
  },
  '& .mass-info-wrapper': {
    flex: '1',
    paddingRight: '25px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '10px',
      marginTop: '25px',
    },
    color: '#454444',
    position: 'relative',
    '& .mass-parish-name': {
      fontSize: '21px',
      lineHeight: '1em',
      [theme.breakpoints.down('md')]: {
        fontSize: '15px',
      },
    },
    '& .mass-language': {
      fontSize: '17px'
    },
    '& .mass-online': {
      '&.visible': {
        display: 'block',
      },
      '& svg': {
        display: 'block',
        width: '27px',
        height: '21px',
        position: 'absolute',
        left: '-35px',
        '& path': {
          fill: theme.palette.primary.main
        }
      },
      position: 'relative',
      marginLeft: '35px',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      fontSize: '17px',
      color: '#454444',
    }
  },
  '& .mass-additional-info-wrapper': {
    flex: '0 0 30%',
    '& h5': {
      fontSize: '14px'
    },
    paddingRight: 0,
  },
  '& .mass-relevance': {
    display: 'block',
    position: 'relative',
    right: '0',
    height: '84px',
    top: -25,
    backgroundColor: '#FFEA00',
    marginBottom: '9px',
    color: '#1A1A1A',
    fontFamily: theme.fonts.calibri.main,
    '& p': {
      fontWeight: 400,
      fontSize: '13px',
      padding: '10px 10px',
      textAlign: 'right',
      lineHeight: '15.87px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
        maxWidth: '135px',
        margin: '0 0 6px 0',
      },
    },
    '& span': {
      fontFamily: theme.fonts.calibri.italic,
      fontWeight: 'bold',
    },
    '&.not-actual::after': {
      borderColor: `transparent transparent ${theme.colors.notActual}`
    },
    '&.mobile': {
      display: 'none',
      position: 'unset',
      padding: '5px 0 0',
      transition: '.2s color',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        cursor: 'pointer',
      },
      '&:hover': {
        color: 'black',
      }
    },
    '&.desktop': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      transition: '.2s color',
      cursor: 'pointer',
      '&:hover': {
        color: 'black',
      }
    },
  },
  '& .mass-actual': {
    position: 'absolute',
    bottom: '-18px',
    right: '0',
    width: '100vw',
    cursor: 'pointer',
    height: '0',
    transition: '.2s color',
    '&:hover': {
      color: 'black',
    },
    '& p': {
      position: 'absolute',
      bottom: '-10px',
      right: '0',
      margin: '0 auto',
    },
    '& span': {
      fontFamily: theme.fonts.calibri.italic,
      fontWeight: 'bold',
      color: '#464646',
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '0',
      right: '0',
      bottom: '18px',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 0 15px 15px',
      borderColor: `transparent transparent ${theme.colors.actual}`
    },
  }
}));


type MassInfoBoxProps = {
  massInfo: MassHoursData,
}

const MassInfoBox = ({
  massInfo,
}: MassInfoBoxProps) => {
  const parish = massInfo.parish;
  const imageSrc = parish.imgPath || '/images/building-in-progress.png';
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();
  const [open, setOpen] = useState(false);

  const handleOpen = (isActual: boolean) => {
    elemClick(isActual ? 'actual' : 'notActual');
    setOpen(true);
  };


  return (
    <PanelWrapper theme={theme}>
      <div className={`mass-image-wrapper info ${massInfo.needUpdate ? 'need-update': ''}`}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <figure className='img-circle'>
            <img className='image' src={imageSrc} alt='Парафия' />
            {
              massInfo.rorate ?
                (
                  <>
                    <div className='overlay'></div>
                    <Raraty className={'rorate'}/>
                  </>
                ) : ''
            }
          </figure>
          {
            massInfo.needUpdate ?
              <div className={'mass-relevance not-actual mobile'} id='open-not-relevant-modal' onClick={() => handleOpen(false)} >
                <p className='not-actual'>
                  {ctx.messNotActual}. <span>{ctx.why}</span>
                </p>
                <p className='not-actual'>
                  {ctx.messNotActualLastUpdate}
                  <br></br>
                  {moment(parish.lastConfirmRelevance, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}
                </p>
              </div> : <></>
          }
        </div>
      </div>
      <div className='mass-info-wrapper info'>
        <h5 className='mass-parish-name'>{parish.name}</h5>
        <h5 className='mass-parish-address'>{parish.address}</h5>
        <h6 className='mass-language'>{ctx.messLangLabel}: {massInfo.langCode}</h6>
        {
          massInfo.online &&
          <a className='mass-online visible' href={parish.broadcastUrl}>
            <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M24.6954 17.1496C25.4926 15.7833 25.8332 13.5804 25.8332 9.99675C25.8332 6.42743 25.4958 4.23725 24.6901 2.83439C23.8827 1.37761 22.925 0.915224 21.0245 0.804238C19.7271 0.716296 16.5025 0.666687 13.0029 0.666687C9.49588 0.666687 6.26986 0.716278 4.96268 0.804927C3.07281 0.915488 2.11497 1.3789 1.30042 2.83972C0.506452 4.23774 0.166504 6.43862 0.166504 10.0098C0.166504 13.5607 0.508098 15.7727 1.3074 17.1661C2.11135 18.6081 3.05392 19.066 4.95988 19.1998C6.32972 19.28 9.73832 19.3334 13.0029 19.3334C16.2602 19.3334 19.6672 19.28 21.0245 19.2007C22.948 19.066 23.8904 18.6074 24.6954 17.1496ZM20.8777 3.13292C22.0664 3.20239 22.2869 3.30925 22.6637 3.99126C23.2123 4.94135 23.4998 6.80069 23.4998 10.0098C23.4998 13.1947 23.2108 15.0642 22.6663 15.9978C22.2881 16.682 22.0692 16.7885 20.8749 16.8722C19.5903 16.9472 16.2163 17 13.0029 17C9.78232 17 6.4067 16.9472 5.10971 16.8713C3.93018 16.7884 3.7081 16.6805 3.33836 16.0175C2.79018 15.0616 2.49984 13.1816 2.49984 9.99675C2.49984 6.81384 2.78863 4.94414 3.33388 3.98401C3.70937 3.31067 3.93302 3.20247 5.10978 3.13359C6.35576 3.04914 9.55145 3.00002 13.0029 3.00002C16.447 3.00002 19.6412 3.04916 20.8777 3.13292ZM10.6665 6.50002L16.4998 10L10.6665 13.5V6.50002Z"/>
            </svg>
            {ctx.messOnline}
          </a>
        }
      </div>
      <div className='mass-additional-info-wrapper info'>
        {
          massInfo.needUpdate ?
            <div className={'mass-relevance not-actual desktop'} id='open-not-relevant-modal-desktop' onClick={() => handleOpen(false)}>
              <p className='not-actual'>
                {ctx.messNotActual}. <span>{ctx.why}</span>
                <br></br>
                {ctx.messNotActualLastUpdate}
                {' '}
                {moment(parish.lastConfirmRelevance, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}
              </p>
              <p className='not-actual'>
              </p>
            </div> :
            <div className={'mass-actual'} id='open-relevant-modal-desktop' onClick={() => handleOpen(true)}>
              <p className='actual'>
                {ctx.messActual}. <span>{ctx.why}</span>
              </p>
            </div>
        }
        <div>
          <h5 className='mass-info'>{massInfo.info}</h5>
        </div>
      </div>
      <SimpleModal
        open={open}
        handleClose={() => setOpen(false)}
        actual={!massInfo.needUpdate}
        lastConfirmRelevance={parish.lastConfirmRelevance}
      />
    </PanelWrapper>
  );
};

export default MassInfoBox;