import { Container } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';


const BannerContainer = styled(Container)<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  margin: '10px auto',
  [theme.breakpoints.down('md')]: {
    margin: 'unset',
  },
  padding: 0,
  fontWeight: '300',
}));


export const Banner = () => {
  const theme = useTheme<DefaultTheme>();

  return (
    <BannerContainer maxWidth={'lg'} theme={theme}>
    </BannerContainer>
  );
};
