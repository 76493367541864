import { TextField } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

const InputField = styled(TextField)<{ theme: DefaultTheme }>(({theme}) => ({
  '& .MuiInputBase-root': {
    padding: '0 0 3px 0',
    lineHeight: '22.4px',
    letterSpacing: 'normal',
    [theme.breakpoints.down('md')]: {
      lineHeight: '18px',
      padding: '3px 0',
      fontSize: '16px',
      fontFamily: 'Calibri',
    },
  },
  '& .MuiInput-input': {
    padding: '0 !important',
    '&::placeholder': {
      fontFamily: `${theme.fonts.calibri.italic}`,
    }
  },
  '& .MuiInput-underline': {
    '&:before, &:after': {
      transition: 'unset',
      borderBottom: `1px dashed ${theme.palette.primary.main}`
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      transition: 'unset',
      borderBottom: `1px dashed ${theme.palette.primary.main}`
    }
  }
}));

export const FilterInput = ({...params}: AutocompleteRenderInputParams) => {
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();

  return (
    <InputField
      {...params}
      variant="standard"
      placeholder={ctx.filterPlaceholder}
      theme={theme}
      multiline
    />
  );
};
