import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import moment from 'moment';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';


const BoxWrapper = styled(Box)<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '218px',
  maxWidth: '1000px',
  border: '2.5px solid',
  borderRadius: '5px',
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    maxWidth: '330px',
    height: '115px',
  },
  '&.actual': {
    borderColor: '#21962D',
  },
  '&.not-actual': {
    borderColor: '#FFEA00',
  },
  '& #modal-modal-description': {
    fontFamily: theme.fonts.calibri.main,
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '29.3px',
    margin: '77px 66px',
    [theme.breakpoints.down('md')]: {
      margin: '38px 22px 29px',
      fontSize: '15px',
      lineHeight: '16px',
    },
  }
}));


interface ModalProps {
  open: boolean,
  actual: boolean,
  lastConfirmRelevance: string,
  handleClose: () => void,
}


const SimpleModal = ({
  open,
  handleClose,
  actual=true,
  lastConfirmRelevance,
} : ModalProps) => {
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      componentsProps={{
        backdrop: {
          style: {
            backdropFilter: 'blur(3px)',
          }
        }
      }}
    >
      <BoxWrapper theme={theme} className={actual ? 'actual' : 'not-actual'}>
        <CloseIcon onClick={handleClose} sx={{
          position: 'absolute' as 'absolute',
          top: '10px',
          right: '10px',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
        }}/>
        <Typography id="modal-modal-description">
          {actual ? ctx.messActualInfo : ctx.messNotActualInfo}, {moment(lastConfirmRelevance, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}
        </Typography>
      </BoxWrapper>
    </Modal>
  );
};

export default SimpleModal;
