import { Banner } from 'components/Banner';
import { Filter } from 'components/Filter';
import { Footer } from 'components/Footer';
import MainHeader from 'components/MainHeader';
import { Menu } from 'components/Menu';
import MobileMenu from 'components/MobileMenu';
import { Schedule } from 'components/Schedule';
import { ScrollButton } from 'components/ScrollToTop';
import { useWindowSize } from 'components/utils';
import { allSettled, fork, serialize } from 'effector';
import { useStore } from 'effector-react';
import { LANGUAGES } from 'locales';
import { $language, $meta, $seo } from 'models/staticData';
import { fetchStaticData } from 'models/staticData';
import type { GetStaticProps, GetStaticPropsContext } from 'next';
import Head from 'next/head';
import React from 'react';


export async function getStaticPaths() {
  return {
    paths: LANGUAGES.map(lang => ({params: {lang}})),
    fallback: false,
  };
}


export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
  const scope = fork();

  await allSettled(fetchStaticData, {scope, params: context?.params?.lang});

  return {
    props: {
      store: serialize(scope),
    }
  };
};

const Home = () => {
  const seo = useStore($seo);
  const _meta = useStore($meta);
  const lang = useStore($language);

  const [width, _] = useWindowSize();
  const isMobile = width <= 900;

  const {
    NEXT_PUBLIC_API_URL
  } = process.env;
  const preloadUrl = `${NEXT_PUBLIC_API_URL}/api/mass/week?lang=${lang}`;

  return (
    <div style={{minWidth: '300px', minHeight: '400px'}}>
      <Head>
        <title>{seo.title}</title>
        <link rel='preconnect' href={NEXT_PUBLIC_API_URL} />
        <link rel='dns-prefetch' href={NEXT_PUBLIC_API_URL} />
        <link rel='preload' as='fetch' href={preloadUrl} crossOrigin='' />
        <link rel="preload" href="/fonts/calibri/Calibri-Bold.woff2" as="font" type="font/woff2" crossOrigin='' />
        <link rel="preload" href="/fonts/calibri/Calibri.woff2" as="font" type="font/woff2" crossOrigin='' />
        <link rel="preload" href="/fonts/calibri/Calibri-Italic.woff2" as="font" type="font/woff2" crossOrigin='' />
        <link rel="preload" href="/fonts/openSans.woff2" as="font" type="font/woff2" crossOrigin='' />
        <link rel="preload" href="/images/footer_church_bg.jpg" as="image" crossOrigin='' />
        <link rel="preload" href="/images/header_bg_desktop.png" as="image" crossOrigin='' />
        <link rel="preload" href="/images/icons/arrow-up.png" as="image" crossOrigin='' />

        <meta
          name="description"
          content={seo.description}
        />
        <meta
          name="title"
          content={seo.title}
        />
        <meta
          name="og:description"
          content={seo.description}
        />
        <meta
          name="og:title"
          content={seo.title}
        />
        {
          _meta.map((elem) =>
            <meta name={elem.key} content={elem.value} key={elem.id}/>
          )
        }
      </Head>
      <MainHeader url=''/>
      {
        isMobile ?
          <Menu
            closedComponent={
              <div>
                <Banner />
                <Filter />
                <Schedule />
                <Footer />
                <ScrollButton />
              </div>
            }
            openComponent={
              <MobileMenu />
            }
            isOpen={false}
          /> :
          <div>
            <Banner />
            <Filter />
            <Schedule />
            <Footer />
            <ScrollButton />
          </div>
      }
    </div>
  );
};

export default Home;
