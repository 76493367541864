import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import { ScheduleComponent } from './ScheduleComponent';

const ScheduleStyled = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  padding: '10px 0 100px',
  background: 'white',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
}));

export const Schedule = () => {
  const theme = useTheme<DefaultTheme>();

  return(
    <ScheduleStyled theme={theme}>
      <ScheduleComponent />
    </ScheduleStyled>
  );
};
