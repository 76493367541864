import { Box, Container, Grid} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useStore } from 'effector-react';
import {$footer} from 'models/staticData/';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

const IntroStyled = styled('div')(() => ({
  position: 'relative',
  fontWeight: '300',
  lineHeight: '1.5em',
  color: '#000',
  background: '#abbdd1 url(/images/footer_church_bg.jpg) 50% no-repeat',
  backgroundSize: 'cover',
  '& h2': {
    fontSize: '20px',
    lineHeight: '70px',
    color: '#fff',
    textAlign: 'center',
  },
}));

const IntroTextStyled = styled('div')<{ theme: DefaultTheme }>(({ theme }) => ({
  borderTop: '1px solid white',
  borderBottom: '1px solid white',
  [theme.breakpoints.down('md')]: {
    padding: '30px 0',
  },
}));

const GridItem = styled(Grid)<{ theme: DefaultTheme }>(({ theme }) => ({
  color: '#254e5a',
  padding: '30px 30px 70px',
  [theme.breakpoints.down('md')]: {
    padding: '10px 30px',
  },
  fontSize: '15px',
}));

export const Footer = () => {
  const theme = useTheme<DefaultTheme>();
  const footerStatic = useStore($footer);

  return(
    <div>
      <IntroStyled>
        <Container maxWidth={'lg'} sx={{
          padding: '0 15px',
        }}>
          <h2>{footerStatic.title}</h2>
          <IntroTextStyled theme={theme}>
            <Grid container columns={{xs:1 , md: 2}} sx={{
              marginRight: '-15px',
              marginLeft: '-15px',
              width: 'unset'
            }}>
              <GridItem item xs={1} theme={theme}>
                <p style={{margin: '0 0 10px'}}>
                  {footerStatic.leftDesc}
                </p>
              </GridItem>
              <GridItem item xs={1} theme={theme}>
                <p style={{margin: '0 0 10px'}}>
                  {footerStatic.rightDescr}
                </p>
              </GridItem>
            </Grid>
          </IntroTextStyled>
          <Grid container justifyContent='flex-end' sx={{
            marginRight: '-15px',
            marginLeft: '-15px',
            width: 'unset'
          }}>
            <Grid item md={8} style={{padding: '30px 30px 70px', fontSize: '19px'}}>
              <p style={{textAlign: 'right', lineHeight: '1.5em', margin: '0 0 10px', color: '#254e5a'}}>
                {footerStatic.bottomText}
                <Box component='a' href={`mailto:${footerStatic.email}`} sx={{
                  fontSize: '24px',
                  margin: '5px 0',
                  color: theme.palette.primary.main,
                }}> {footerStatic.email}</Box>
              </p>
            </Grid>
          </Grid>
        </Container>
      </IntroStyled>
      <Box component='footer' sx={{
        position: 'relative',
        padding: '20px 0',
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#161616',
      }}>
        <Container maxWidth={'lg'} sx={{
          padding: '0 15px',
        }}>
          <Box sx={{
            marginRight: '-15px',
            marginLeft: '-15px',
            width: 'unset'
          }}>
            <div>
              <p style={{margin: '0 0 10px'}}>
                Copyright {new Date().getFullYear()} by Imsha.by | All rights reserved
              </p>
            </div>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
