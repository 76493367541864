import { SvgIcon } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useEvent, useStore } from 'effector-react';
import { useLang } from 'locales';
import { $filter, $filterOptions, changeFilter } from 'models/schedule';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import RorateCandle from './raraty.svg';
import {SwitchFilterProps} from './types';

const Container = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  flex: 1,
}));

const FilterContainer = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'flex',
  '& .title': {
    marginLeft: '10px',
    padding: 0,
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    position: 'relative',
    font: `normal 17px/20.75px ${theme.fonts.calibri.main}`,
    '& .subtitle': {
      font: `normal 15px/18.3px ${theme.fonts.calibri.main}`,
    },
    display: 'flex',
    flex: '1'
  },
  '&.disabled': {
    opacity: '.3',
    pointerEvents: 'none',
  }
}));

const FilterWrapper = styled('a')<{ theme: DefaultTheme }>(({theme}) => ({
  margin: 0,
  padding: 0,
  backgroundColor: 'rgba(93,62,129,.03)',
  paddingBottom: '15px',
  borderRadius: '0 0 10px 10px',
  order: '1',
  alignItems: 'center',
  paddingLeft: '10px',
  display: 'flex',
  font: `normal 24px/29.3px ${theme.fonts.calibri.main}`,
  color: '#454444',
  flex: '1'
}));

const YoutubeIconStyled = styled(SvgIcon)<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'block',
  marginLeft: '8px',
  height: '12px',
  width: '13px',
  fill: theme.palette.primary.main,
}));

const RorateCandleStyled = styled(RorateCandle)<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'block',
  marginLeft: '8px',
  height: '50px',
  width: '50px',
  fill: 'white',
  '& path': {
    stroke: theme.palette.primary.main,
  }
}));

const SwitchStyled = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  width: '24px',
  height: '24px',
  borderRadius: '4px',
  border: `2px solid ${theme.palette.primary.main}`,
  position: 'relative',
  '&.active::after': {
    display: 'block',
    content: "''",
    width: '13px',
    height: '13px',
    position: 'absolute',
    top: '3px',
    left: '4px',
    // eslint-disable-next-line quotes
    background: `url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='10' fill='none' viewBox='0 0 14 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.887 9.917l-4.8-4.55a.282.282 0 0 1-.087-.2.27.27 0 0 1 .087-.2L1.3 3.811a.306.306 0 0 1 .419 0l3.16 2.994c.115.11.303.11.42-.001l6.98-6.72A.308.308 0 0 1 12.7.083l1.213 1.156c.116.11.116.289.001.4L6.308 8.954l.002.001-1.003.961a.308.308 0 0 1-.42 0z' fill='%2374767B'/%3E%3C/svg%3E") no-repeat 50%`,
    backgroundSize: 'auto',
  },
}));


export const MobileFilter = ({
  containerSx,
}: SwitchFilterProps) => {
  const theme = useTheme<DefaultTheme>();
  const setFilter = useEvent(changeFilter);
  const filterState = useStore($filter);
  const filter = useStore($filterOptions);

  const disabledOnline = (filter.online.filter((e) => e.value === 'true').length === 0);
  const disabledRorate = (filter.rorate.filter((e) => e.value === 'true').length === 0);

  const ctx = useLang();

  return (
    <Container theme={theme} sx={containerSx}>
      <FilterContainer theme={theme} className={disabledOnline ? 'disabled' : ''}>
        <FilterWrapper href="#" onClick={(e) => {
          e.preventDefault();
          setFilter({
            online: !filterState.online,
          });
        }} theme={theme}>
          <SwitchStyled theme={theme} className={filterState.online ? 'active' : ''}>
          </SwitchStyled>
          <div className='title'>
            <span>{ctx.filterAttendance.title}</span>
            <YoutubeIconStyled theme={theme} viewBox='0 0 13 12'>
              <path fillRule="evenodd" clipRule="evenodd" d="M12.3474 10.2896C12.746 9.4699 12.9163 8.14811 12.9163 5.99794C12.9163 3.85635 12.7476 2.54224 12.3448 1.70052C11.9411 0.826456 11.4623 0.549024 10.512 0.482433C9.86329 0.429668 8.25099 0.399902 6.50123 0.399902C4.7477 0.399902 3.13468 0.429657 2.48109 0.482847C1.53616 0.549183 1.05724 0.827228 0.649968 1.70372C0.252982 2.54253 0.0830078 3.86306 0.0830078 6.00578C0.0830078 8.13633 0.253805 9.46351 0.653456 10.2996C1.05543 11.1648 1.52672 11.4395 2.47969 11.5198C3.16462 11.5679 4.86892 11.5999 6.50123 11.5999C8.12988 11.5999 9.83335 11.5679 10.512 11.5203C11.4738 11.4395 11.945 11.1643 12.3474 10.2896ZM10.4386 1.87965C11.033 1.92134 11.1432 1.98545 11.3316 2.39466C11.6059 2.96471 11.7497 4.08032 11.7497 6.0058C11.7497 7.91671 11.6052 9.03845 11.3329 9.59861C11.1438 10.0091 11.0344 10.073 10.4372 10.1232C9.7949 10.1682 8.10794 10.1999 6.50125 10.1999C4.89093 10.1999 3.20313 10.1682 2.55463 10.1227C1.96486 10.073 1.85382 10.0082 1.66895 9.61038C1.39486 9.03688 1.24969 7.90884 1.24969 5.99796C1.24969 4.08821 1.39409 2.96639 1.66672 2.39031C1.85446 1.9863 1.96628 1.92138 2.55466 1.88006C3.17765 1.82939 4.7755 1.79992 6.50125 1.79992C8.22329 1.79992 9.82039 1.8294 10.4386 1.87965ZM5.33293 3.89983L8.2496 5.99983L5.33293 8.09983V3.89983Z"/>
            </YoutubeIconStyled>
          </div>
        </FilterWrapper>
      </FilterContainer>
      {/* <FilterContainer theme={theme} className={disabledRorate ? 'disabled' : ''}>
        <FilterWrapper href="#" onClick={(e) => {
          e.preventDefault();
          setFilter({
            rorate: !filterState.rorate,
          });
        }} theme={theme}>
          <SwitchStyled theme={theme} className={filterState.rorate ? 'active' : ''}>
          </SwitchStyled>
          <div className='title'>
            <span>{ctx.filterRorate.title}</span>
            <RorateCandleStyled theme={theme} viewBox='30 -35 180 180' />
          </div>
        </FilterWrapper>
      </FilterContainer> */}
    </Container>
  );
};
