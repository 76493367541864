import { styled } from '@mui/material/styles';
import { DefaultTheme } from 'styles/Theme/types';

export const FilterField = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  marginBottom: '41px',
  display: 'flex',
  '& .fieldLabel': {
    paddingRight: '20px',
    color: theme.palette.primary.main,
    font: `normal 20px/24px ${theme.fonts.calibri.bold}`,
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px',
      height: 'auto',
      lineHeight: '24px',
      fontSize: '17px',
    }
  },
  '& .MuiAutocomplete-root': {
    flex: '1',
    position: 'static'
  },
  '& .MuiAutocomplete-listbox': {
    backgroundColor: '#fff',
    margin: '0',
    padding: '0',
    maxHeight: '240px',
    boxSizing: 'border-box'
  },
  '& .MuiAutocomplete-clearIndicator': {
    color: theme.palette.primary.main,
    width: '18px',
    height: '18px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '55%',
    position: 'absolute',
    left: '-18px',
    top: '4px',
    visibility: 'visible',
    boxSizing: 'border-box',
    '& .MuiSvgIcon-root': {
      width: '12px',
      height: '12px',
    }
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none'
  },
  '& .MuiAutocomplete-paper': {
    marginTop: '5px',
    width: '100%',
    [theme.breakpoints.down('md')]:{
      width: '105%',
      position: 'absolute',
      left: '-8px',
    }
  },
  '& .MuiAutocomplete-noOptions': {
    color: '#ccc',
    backgroundColor: `${theme.palette.primary.main}11`,
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '24px',
    flexDirection: 'row',
    position: 'relative',
    lineHeight: '18px',
  }
}));

export const OptionStyled = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  color: '#454444',
  lineHeight: '60px',
  font: `normal 17px ${theme.fonts.calibri.main}`,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  padding: '0 26px 0 15px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    lineHeight: '21px',
    padding: '8px',
  },
  '&:nth-child(odd), &:hover': {
    backgroundColor: `${theme.palette.primary.main}1a`,
  },
  '& span': {
    boxSizing: 'border-box',
  }
}));
