import { Autocomplete, AutocompleteRenderOptionState, PopperProps } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { FilterField, OptionStyled } from 'components/Filter/Fields/style';
import { FilterInput } from 'components/Filter/FilterInput';
import { useWindowSize } from 'components/utils';
import { useEvent, useStore } from 'effector-react';
import { useLang } from 'locales';
import { $filter, $filterOptions, changeFilter } from 'models/schedule';
import { FilterCity, FilterParish } from 'models/schedule/types';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { DefaultTheme } from 'styles/Theme/types';


type AutocompleteValue = {
  label: string;
  id: string;
};


export const CityField = () => {
  const theme = useTheme<DefaultTheme>();

  const ctx = useLang();
  const options = useStore($filterOptions);
  const filter = useStore($filter);
  const setFilter = useEvent(changeFilter);
  const [width, _] = useWindowSize();
  const [__, setCookie] = useCookies();
  const [value, setValue] = useState<FilterParish | undefined | null>(filter.city);
  const isMobile = width <= 900;
  const [selectOptions, setselectOptions] = useState<FilterParish[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setselectOptions(options.city.map(
      instance => (
        {
          label: instance.name,
          id: instance.value
        }
      )
    ));
  }, [options]);

  useEffect(() => {
    const selectedOption = selectOptions.find(elem => elem.id === filter.city?.id);
    if (selectedOption) {
      setCookie('cityId', selectedOption?.id, { domain: '.imsha.by'});
    }
    setValue(selectedOption);
  }, [setCookie, filter.city, filter.city?.id, filter.lang, selectOptions]);


  const onChange = (_: React.SyntheticEvent, city:AutocompleteValue | null, reason: String): void => {
    if (reason === 'clear') setOpen(true);
    const selectedOption = selectOptions.find(elem => elem.id === city?.id) || null;
    if (selectedOption) {
      setValue(selectedOption);
      setCookie('cityId', selectedOption?.id, { domain: '.imsha.by'});
      setFilter({
        city: selectedOption,
        parishId: null,
        massLang: null,
      });
    }
  };

  const commonPopperProps: Partial<PopperProps> = {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: false,
          rootBoundary: 'document',
          padding: 8,
        },
      },
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altAxis: false,
          altBoundary: false,
          tether: false,
          rootBoundary: 'viewport',
          padding: 8,
        },
      }
    ]
  };
  const mobilePopperProps: Partial<PopperProps> = {
    placement: 'auto-end',
    style: {
      width: '100%',
    }
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLElement>,
    option: FilterCity,
    state: AutocompleteRenderOptionState
  ) => {
    return (
      <OptionStyled theme={theme} {...props}>
        <span>{option.label}</span>
      </OptionStyled>
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: FilterCity) => option.label,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FilterField theme={theme} sx={{
      width: '64%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        order: '1'
      }
    }}>
      <div className='fieldLabel'>
        {ctx.filterCityLabel}
      </div>
      <Autocomplete
        disablePortal
        componentsProps={{
          popper: isMobile ? {...mobilePopperProps, ...commonPopperProps} : {...commonPopperProps}
        }}
        renderOption={renderOption}
        getOptionLabel={(option) => option.label}
        options={selectOptions}
        fullWidth
        renderInput={(params) => <FilterInput {...params}/>}
        onChange={onChange}
        value={value}
        noOptionsText={ctx.noOptionsText}
        isOptionEqualToValue={(option, value) => (option && option.id) === (value && value.id)}
        filterOptions={filterOptions}
        blurOnSelect
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </FilterField>
  );
};
