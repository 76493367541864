import {styled, useTheme} from '@mui/material/styles';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

const TabBtn = styled('li')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  textAlign: 'center',
  lineHeight: '1.4em',
  padding: '17px 10px',
  borderBottom: '1px solid #dadada',
  borderRight: '1px solid #dadada',
  fontFamily: theme.fonts.calibri.main,
  cursor: 'pointer',
  transition: 'all .3s ease',
  WebkitTransition: 'all .3s ease',
  color: theme.palette.primary.main,
  listStyle: 'none',
  [theme.breakpoints.down('md')]: {
    flex: 1,
    float: 'none',
    border: '1px solid #d0d0d0 !important',
    marginBottom: '0',
    padding: '1px 0'
  },
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRightColor: theme.palette.primary.main
  },
  '&.disabled': {
    opacity: '.3',
    pointerEvents: 'none',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    borderRightColor: theme.palette.primary.main,
    color: '#fff'
  },
  '&:hover .arrow, &.active .arrow': {
    right: '-17px',
    bottom: 'auto',
    opacity: '1',
    transition: 'all .3s ease .2s',
    WebkitTransition: 'all .3s ease .2s',
  },
}));

const DayStyled = styled('span')(() => ({
  display: 'block',
  textTransform: 'capitalize',
  fontSize: '24px',
  lineHeight: '36px',
  marginBottom: '0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const DateStyled = styled('span')(() => ({
  display: 'block',
  fontSize: '15px'
}));

const CurveStyled = styled('span')<{ theme: DefaultTheme }>(({theme}) => ({
  bottom: 'auto',
  width: '20px',
  height: '100%',
  left: 'auto',
  top: '0',
  position: 'absolute',
  display: 'block',
  textAlign: 'center',
  opacity: '0',
  '&::after': {
    boxSizing: 'border-box',
    content: '""',
    position: 'absolute',
    right: '3px',
    top: '42px',
    display: 'inline-block',
    width: '0',
    height: '0',
    border: '7px solid transparent',
    borderLeftColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

type NavBtnProps = {
  date: Date;
  activeDate: Date;
  day: string;
  dateHumanFormat: string;
  onClick: Function;
  disabled: boolean | false;
}

const NavBtn = ({
  date,
  activeDate,
  day,
  dateHumanFormat,
  onClick,
  disabled,
}: NavBtnProps) => {
  const theme = useTheme<DefaultTheme>();

  const active = date.getTime() === activeDate.getTime();

  return (
    <TabBtn theme={theme} className={`${(active ? 'active' : '')} ${disabled ? 'disabled' : ''}`} onClick={() => onClick(date)}>
      <DayStyled>{day}</DayStyled>
      <DateStyled>{dateHumanFormat}</DateStyled>
      <CurveStyled theme={theme} className={'arrow'}></CurveStyled>
    </TabBtn>
  );
};

export default NavBtn;
