import {styled, useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import SvgArrow from './arrow.svg';

const FiltersButton = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  font: `normal 24px/29px ${theme.fonts.calibri.main}`,
  cursor: 'pointer',
  flex: '1',
  alignItems: 'center',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    font: `normal 17px/20.75px ${theme.fonts.calibri.main}`,
    width: '100%',
    display: 'flex',
  },
}));

const FiltersIcon = styled('svg')<{ theme: DefaultTheme }>(({theme}) => ({
  marginRight: '12px',
  fill: theme.palette.primary.main,
  '& line': {
    stroke: theme.palette.primary.main
  },
  height: '26px',
  width: '26px',
}));

const ArrowIcon = styled(SvgArrow)<{ theme: DefaultTheme }>(({theme}) => ({
  strokeWidth: 2,
  [theme.breakpoints.down('md')]: {
    right: 0,
    position: 'absolute',
    margin: 0,
  },
  marginLeft: '15px',
  stroke: theme.palette.primary.main,
  fill: 'none',
  transition: 'rotate .5s',
  '&.down': {
    rotate: '-180deg',
  }
}));

interface FilterButtonProps {
  onClick: (event: React.MouseEvent) => void,
  active?: boolean,
}

export const FilterButton = ({
  onClick,
  active=true,
}: FilterButtonProps) => {
  const ctx = useLang();
  const theme = useTheme<DefaultTheme>();

  return (
    <FiltersButton theme={theme} onClick={onClick}>
      <FiltersIcon theme={theme} viewBox='0 0 26 27' width='26px' height='27px'>
        <line stroke="#5D3E81" strokeLinecap="round" strokeWidth="2.5" x1="4.2334" x2="21.7334" y1="7.02197" y2="7.02197"></line><circle _ngcontent-ddm-c1="" r="3" transform="matrix(1 0 0 -1 7.99609 7.02197)"></circle><ellipse _ngcontent-ddm-c1="" fill="white" rx="1.5" ry="1.50251" transform="matrix(1 0 0 -1 7.99609 7.02679)"></ellipse><line _ngcontent-ddm-c1="" strokeLinecap="round" strokeWidth="2.5" x1="4.2334" x2="21.7334" y1="14.0093" y2="14.0093"></line><circle _ngcontent-ddm-c1="" cx="17.1836" cy="14.022" r="3"></circle><ellipse _ngcontent-ddm-c1="" fill="white" rx="1.5" ry="1.50251" transform="matrix(1 0 0 -1 17.1836 14.0273)"></ellipse><line _ngcontent-ddm-c1="" stroke="#5D3E81" strokeLinecap="round" strokeWidth="2.5" x1="4.2334" x2="21.7334" y1="21.0317" y2="21.0317"></line><circle _ngcontent-ddm-c1="" cx="10.9961" cy="21.022" r="3"></circle><circle _ngcontent-ddm-c1="" fill="white" r="1.5" transform="matrix(1 0 0 -1 10.9961 21.0225)"></circle>
      </FiltersIcon>
      <span>{ctx.filterName}</span>
      <ArrowIcon theme={theme} className={active ? 'down' : ''}/>
    </FiltersButton>
  );
};
