import {styled, useTheme} from '@mui/material/styles';
import { MassHours } from 'models/schedule/types';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import ContentRow from '../ContentRow';

const ScheduleTabWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  padding: '40px 5px 10px 20px',
  position: 'relative',
  display: 'block',
  [theme.breakpoints.down('md')]: {
    paddingTop: '0'
  }
}));

type ScheduleTabProps = {
  massHours: MassHours[]
}

export const ScheduleTab = ({
  massHours,
}: ScheduleTabProps) => {
  const theme = useTheme<DefaultTheme>();

  return (
    <ScheduleTabWrapper theme={theme}>
      {
        massHours.map((masses, key) =>
          <ContentRow
            hour={masses.hour}
            massesData={masses.data}
            count={masses.data.length}
            key={key}
          />
        )
      }
    </ScheduleTabWrapper>
  );
};

export default ScheduleTab;