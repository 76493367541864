import {styled, useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import { MassHoursData } from 'models/schedule/types';
import React, {useEffect, useState} from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import MassInfoBox from './MassInfoBox';

const ContentRowWrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  paddingLeft: '150px',
  color: '#7f7f7f',
  paddingBottom: '20px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '45px',
    paddingTop: '5px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '105px',
    top: '24px',
    width: '2px',
    height: '100%',
    background: '#d2d2d2',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      left: '11px'
    }
  },
  '&:last-child::before': {
    display: 'none'
  },
  '& .hour': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '65px',
    fontSize: '14px',
    color: '#7f7f7f',
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: '15px',
    }
  },
  '& .circle': {
    position: 'absolute',
    left: '94px',
    top: 0,
    width: '24px',
    height: '24px',
    border: '2px solid #dadada',
    borderRadius: '50%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      left: '0'
    }
  },
  '& .toggle-btn': {
    position: 'relative',
    top: '-3px',
    marginBottom: '11px',
    cursor: 'pointer',
    '& h3': {
      position: 'relative',
      display: 'inline-block',
      lineHeight: '28px',
      paddingRight: '15px',
      color: '#454444',
      fontSize: '18px',
      [theme.breakpoints.down('md')]: {
        fontSize: '15px',
      }
    },
    '& .arrow': {
      transition: '.5s',
      rotate: '180deg',
      '&.down': {
        rotate: '0deg',
      }
    },
    '& .arrow path': {
      stroke: theme.palette.primary.main,
    },
  },
  '&.active-box': {
    [theme.breakpoints.down('md')]: {
      paddingTop: '2px',
    },
    '&::before': {
      background: theme.palette.primary.main
    },
    '& .circle': {
      borderColor: theme.palette.primary.main
    },
    '& .hour': {
      fontSize: '19px'
    }
  },
}));

const MassesBox = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  paddingRight: '20px',
  marginRight: '-15px',
  marginLeft: '-15px',
  display: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 0 0 -15px',
    padding: '0'
  },
  '&.active': {
    display: 'block'
  },
}));

type ContentRowProps = {
  hour: string,
  count: number,
  massesData: MassHoursData[],
}

const ContentRow = ({
  hour, count, massesData
}: ContentRowProps) => {
  const theme = useTheme<DefaultTheme>();

  const ctx = useLang();
  const [active, setActive] = useState(true);

  useEffect(()=>{
    setActive(true);
  }, [massesData]);

  return (
    <ContentRowWrapper theme={theme} className={`${active ? 'active-box' : ''}`}>
      <div className='hour'>{hour}</div>
      <div className='circle'><span></span></div>
      <div className='toggle-btn' onClick={() => setActive(!active)}>
        <h3>{ctx.toggleBtnTitle(count)}</h3>
        <svg fill="none" height="8" viewBox="0 0 13 8" width="13" xmlns="http://www.w3.org/2000/svg" className={`arrow ${active ? 'down' : ''}`}>
          <path _ngcontent-mhc-c1="" d="M1 7L6.5 2L12 7" stroke="#5D3E81" strokeLinecap="round" strokeWidth="2"></path>
        </svg>
      </div>
      <MassesBox theme={theme} className={`${active ? 'active' : ''}`}>
        {
          massesData.map((massInfo, index) =>
            <MassInfoBox
              key={index}
              massInfo={massInfo}
            />
          )
        }
      </MassesBox>
    </ContentRowWrapper>
  );
};

export default ContentRow;
